import React from 'react'

import GlobalContext from '../utils/globalContext'
import Root from '../layouts/Root'
import Button from '../components/Button'

const NotFoundPage = () => (
  <Root>
    <GlobalContext.Consumer>
      {({ i18n }) => (
        <main>
          <div className="t-center" style={{ margin: '80px 0' }}>
            <p className="t-heading-s">{i18n.notFound.title}</p>
            <br />
            <Button type="link" to="/" color="confirm">
              {i18n.notFound.link}
            </Button>
          </div>
        </main>
      )}
    </GlobalContext.Consumer>
  </Root>
)

export default NotFoundPage
